import React, { useEffect, useState } from 'react';
import Actions from './Actions';
import {storage} from "./firebase";
const Person = ({ person, modifySuperficialChoices }) => {
  const {  url } = person;
  console.log(url)
  const [image, setImage] = useState("")
  useEffect(() => {
    const getUrl = async () => {
      const imageURL = await storage.child(url).getDownloadURL();
      setImage(imageURL)
      console.log(image)
    }

    getUrl()
    const handleKeyDown = (event) => {
      switch (event.key) {

        case "g":
          modifySuperficialChoices(person, "ADD_TO_LIKED_USERS");
          break;
        case "b":
          modifySuperficialChoices(person, "ADD_TO_DISLIKED_USERS");
          break;
        default:
        // do nothing.
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [person, modifySuperficialChoices]);

  return (
    <>
      <div className="person">
        <div className="person-photo" style={{width:"700px", height:"700px"}}>
          <img src={image}  />
        </div>

        {/*<div className="person-description">*/}
        {/*  <p className="person-name-age">*/}
        {/*    {name}, <span>{age}</span>*/}
        {/*  </p>*/}
        {/*  <p className="person-info">{desc}</p>*/}
        {/*</div>*/}
      </div>

      <Actions
        person={person}
        modifySuperficialChoices={modifySuperficialChoices}
      />
    </>
  );
};

export default Person;
