import React, {useEffect, useState} from 'react';
import './App1.css';
import Person from './components/Person';
import {database, storage, dbQuery} from './components/firebase'

const arr = []

const getBaseEpoch = (filename, type) => {
  // get the second occurrence of _
  const arr = filename.split("_");
  switch (type) {
    case "image":
      const s = arr[arr.length - 1];
      return s.substring(0, s.indexOf("."));
      break;
    case "thumbnail":
      return arr[arr.length-2];
      break;
    default:
      return filename;
  }
};
const getStoreType = (imagePath) => {
  const arr = imagePath.split("_")
  return arr[2]+" "+arr[3]
}
const App1 = () => {
  const [people, setPeople] = useState([]);
  const [likedUsers, setLikedUsers] = useState([]);
  const [superLikedUsers, setSuperLikedUsers] = useState([]);
  const [dislikedUsers, setDislikedUsers] = useState([]);
  const activeUser = 0;
  const updateDB = (userId, status)=> {
    let updatedData = userId.folder;
    updatedData["qc"]=status
    dbQuery.child(userId.key).set(updatedData);
  }
  const removedPersonFromDataSrc = (peopleSource, userId) =>
    peopleSource.filter(user => user.url !== userId.url);

  const modifySuperficialChoices = (userId, action) => {


    switch (action) {
      case 'ADD_TO_LIKED_USERS':
        if(people.length === 1) {
          alert("last image");
        }
        updateDB(userId,"passed");
          setPeople(removedPersonFromDataSrc(people, userId));

        break;
      case 'ADD_TO_DISLIKED_USERS':

        if(people.length === 1) {
          alert("last image");
        }
        console.log("send qc fail");
        updateDB(userId,"failed");
          setPeople(removedPersonFromDataSrc(people, userId));

        break;
      default:
        return people;
    }
  };

  //componen
  useEffect( () =>{
      database.once("value", async  function(snapshot) {
        let val = snapshot.val()
        const fetchedImages = await Promise.all( Object.keys(val).filter(key=>val[key].qc === "pending" && "thumbnailPath" in val[key]).map(async (key)=>{
          //console.log(val);
          let folder = val[key];
          let imgPath = folder.thumbnailPath
         // console.log(imgPath);
          let url;
             url = imgPath;
            if(imgPath.includes("thumbnails"))
              //console.log(url)
              return {url,folder, key, uploadedBy :folder.uploadedBy, storeType: getStoreType(imgPath)};


          //  console.log(1)
           // console.log({url})

        }))
       // console.log(2)
        console.log(fetchedImages.length)
        setPeople(fetchedImages)
      })
    // storage.listAll()
    //     .then(list => {
    //       let i =0;
    //      // console.log(list.items.length)
    //       list.items.map(item => {
    //         console.log(item.fullPath)
    //         const epochName = getBaseEpoch(item.fullPath,"image");
    //         database.ref("/"+epochName).once("value",snapshot => {
    //           //console.log(snapshot.val())
    //           if(snapshot.exists()) {
    //             const data = snapshot.val()
    //             console.log(data)
    //             if (data.qc === 'passed') {
    //               // const ob ={}
    //               // ob["/"+epochName+"qc"]="pending"
    //              // database.ref("/"+epochName+"/qc").set("pending")
    //               // database.ref().update(ob)
    //               //     .then(resp => console.log("table entry successfully made ", epochName))
    //               //     .catch(err => console.log("error in creating a new entry, for ", epochName, " err is ", err))
    //               //return
    //             }
    //
    //
    //           } else {
    //             // console.log("does not exists ", epochName)
    //             // const obj ={}
    //             // const imageObj = {}
    //             // imageObj["qc"] = "pending";
    //             // imageObj["filePath"] = item.fullPath;
    //             // imageObj["suffix"] = getSuffix(item.name);
    //             // imageObj["uploadedBy"] = getUserName(item.name);
    //             // imageObj["thumbnailPath"] = getThumbnailPath(item.fullPath)
    //             // obj[epochName] = imageObj
    //             console.log("updating ", item.name)
    //             // database.ref().update(obj)
    //             //     .then(resp => console.log("table entry successfully made ", epochName))
    //             //     .catch(err => console.log("error in creating a new entry, for ", epochName, " err is ", err))
    //
    //           }
    //         })
    //       })
    //     });

    // database.ref("/").once("value", snapshot => {
    //   snapshot.forEach(folder => {
    //     const data = folder.val()
    //
    //     if (data['qc'] === "passed") {
    //       console.log(data)
    //
    //     }
    //   })
    // })

    // database.ref("/1611997254173").once("value", snapshot => {
    //   if(snapshot.exists())
    //   console.log(Object.keys(snapshot.val()).length)
    //   else
    //     console.log("does not exists")
    // })
    // storage.listAll()
    //     .then(list => {
    //       let i =0;
    //      // console.log(list.items.length)
    //       list.items.map(item => {
    //         console.log(item.fullPath)
    //         console.log(item.name)
    //       })
    //     })


    }, []);
  return (
      <div className="container">
      <div className="row">
        <div className="col-3">

          <div>
            {people.length > 0 ? (
            <table className={'table-active'}>
              <tr>
                <td>
                  Uploaded By
                </td>
                <td>
                  {people[0].uploadedBy}
                </td>
              </tr>
              <tr>
                <td>
                  Store Type
                </td>
                <td>
                  {people[0].storeType}
                </td>
              </tr>
            </table>) : null}
          </div>

        </div>
        <div className="col-6">
        {people.length > 0 ? (
            // people[1] ? (
                <Person
                    key={people[0].key}
                    person={people[0]}
                    modifySuperficialChoices={modifySuperficialChoices}
                    likedUsers={likedUsers}
                />

        ) : (
            <div>
              No images to QC available at the moment!
            </div>
        )}
        </div>
      </div>
      </div>
  );
};

export default App1;
