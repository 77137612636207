import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDMIUzTFCpy3zNnYwuZD3V2ZQUGLtIzJpc",
    authDomain: "retail-pulse-app-feea6.firebaseapp.com",
    databaseURL: "https://retail-pulse-image-master.firebaseio.com/",
    projectId: "retail-pulse-app-feea6",
    storageBucket: "retail-pulse-app-feea6.appspot.com",
    messagingSenderId: "363230336725",
    appId: "1:363230336725:web:7a1ad8aa03c082e0e3b45c",
    measurementId: "G-K2MRY6GN0S"
};

let firebaseApp;
if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
}else {
    firebase.app(); // if already initialized, use that one
}
    //= firebase.initializeApp(firebaseConfig);

const database = firebaseApp.database().ref("/");
const dbQuery = firebase.database().ref("/")
const storage = firebaseApp.storage().ref("/");
const auth = firebaseApp.auth();

export { database, storage, auth, dbQuery };

