import React, {useEffect, useState, Component} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import './app.css';
import App1 from './App1';
import {auth} from './components/firebase' ;

// Configure Firebase.
const config = {
    apiKey: 'AIzaSyDMIUzTFCpy3zNnYwuZD3V2ZQUGLtIzJpc',
    authDomain: 'retail-pulse-app-feea6.firebaseapp.com',
    // ...
};
//firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

function SignInScreen() {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        auth.signInWithEmailAndPassword(email, password)
            .then((error) => {
                console.log(error)
            })
            .catch((e) => {
                console.log(e);
                alert("Invalid email/password")
            });
    };


    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (!isSignedIn) {
        return (

            <div>
                <nav className="navbar navbar-light bg-light" style={{width: "100%", paddingBottom: "15%"}}>
                    <a className="navbar-brand" href="#">
                        <img src="https://retailpulse.ai/images/logo.png" width="30" height="25"
                             className="d-inline-block align-top" alt=""/>
                        Retail Pulse
                    </a>
                </nav>
                <div className="container ">
                    <div className="wrapper fadeInDown">
                        <div id="formContent">

                            <form onSubmit={e => handleSubmit(e)}>
                                <input type="text" id="login" className="fadeIn second" name="login" placeholder="login"
                                       onChange={onEmailChange}/>
                                <input type="password" id="password" className="fadeIn third" name="password"
                                       placeholder="password" onChange={onPasswordChange}/>
                                <input type="submit" className="fadeIn fourth" value="Log In"/>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div>
            <nav className="navbar navbar-light bg-light" style={{width: "100%", paddingBottom: "3%"}}>
                <a className="navbar-brand" href="#">
                    <img src="https://retailpulse.ai/images/logo.png" width="30" height="25"
                         className="d-inline-block align-top" alt=""/>
                    Retail Pulse
                </a>
                <div>
                    <a onClick={() => firebase.auth().signOut()}>
                        <button className="btn btn-danger">Sign-out</button>
                    </a>
                </div>
            </nav>
            <div className="container">
                <div className="alert alert-info" role="alert">You can use G or B for selecting or rejecting
                            images
                </div>
                <App1/>
            </div>
        </div>
    );
}

export default SignInScreen;
